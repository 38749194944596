








import { Component, Prop, Vue } from 'vue-property-decorator';
import { AuthorizationMixin } from "@/views/mixin";

@Component
export default class CreateLetterButton extends AuthorizationMixin {
  @Prop({ type: String, required: true }) contact!: string;
  @Prop({ type: Array, required: true }) receiverTypes!: string[];
  @Prop({ type: String }) financeAccount?: string;
  @Prop({ type: String }) conservatorship?: string;
  @Prop({ type: String }) contactPerson?: string;
  @Prop({ type: Object }) receiver?: object;
  @Prop({ type: Boolean, default: false }) hideIcon!: boolean;
}
